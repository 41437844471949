import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import { devices } from "./modules/devices";
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import BillingModule from "@/store/modules/BillingModule"
import ConfigModule from "@/store/modules/ConfigModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    devices,
    BillingModule
  },
});

export default store;
