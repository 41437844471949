import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/provisioning/devices/",
        name: "provisioning",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "/bins",
            name: "device-overview",
            component: () => import("@/views/provisioning/devices/Bins.vue"),
          },
          {
            path: "/trackers",
            name: "tracker-overview",
            component: () =>
              import("@/views/provisioning/devices/trackers.vue"),
          },
          {
            path: "/provisioning/devices/uplink/:id",
            name: "uplink-overview",
            component: () => import("@/views/provisioning/devices/Uplink.vue"),
          },
          {
            path: "repo_to_platform",
            name: "device-projects",
            component: () =>
              import("@/views/provisioning/devices/RepoToPlatform.vue"),
          },
          {
            path: "add_repo",
            name: "device-campaigns",
            component: () =>
              import("@/views/provisioning/devices/Add_Repo.vue"),
          },
          {
            path: "connections",
            name: "device-connections",
            component: () =>
              import("@/views/provisioning/devices/Connections.vue"),
          },
          {
            path: "repository",
            name: "device-repository",
            component: () =>
              import("@/views/provisioning/devices/Repository.vue"),
          },
        ],
      },
      {
        path: "/administration/notifications",
        name: "apps-administration-notification",
        component: () =>
          import("@/views/apps/administration/notifications/Notifications.vue"),
      },
      {
        path: "/administration/billing",
        name: "apps-administration-billing",
        component: () =>
          import("@/views/apps/administration/billing/billing.vue"),
      },
      {
        path: "/administration/users",
        name: "apps-administration-users",
        component: () =>
          import("@/views/apps/administration/users/UsersList.vue"),
      },
      {
        path: "/help/viewer",
        name: "apps-help-section",
        component: () => import("@/views/help/HelpSection.vue"),
      },
      {
        path: "/production",
        name: "production",
        component: () => import("@/views/help/Production.vue"),
      },
      {
        path: "/credentialsheets",
        name: "credentialsheets",
        component: () => import("@/views/help/CredentialSheets.vue"),
      },
      {
        path: "/devicereport",
        name: "devicereport",
        component: () => import("@/views/DataAnalysis/DeviceReport.vue"),
      },
      {
        path: "/companyreport",
        name: "companyreport",
        component: () => import("@/views/DataAnalysis/CompanyReport.vue"),
      },
      {
        path: "/downlink/vie",
        name: "downlink-managment",
        component: () => import("@/views/downlink/Downlink.vue"),
      },
      {
        path: "/webhooks",
        name: "webhooks-management",
        component: () => import("@/views/webhooks/Webhooks.vue"),
      },

      {
        path: "/management/permission",
        name: "permissions-managment",
        component: () => import("@/views/permissions/Permission.vue"),
      },

      {
        path: "/management/routing",
        name: "routing-managment",
        component: () => import("@/views/routing/routing.vue"),
      },

      {
        path: "/help/adddevice",
        name: "help-add-device",
        component: () => import("@/views/help/files/AddDevice.vue"),
      },
      {
        path: "/help/addbins",
        name: "help-add-bins",
        component: () => import("@/views/help/files/AddBins.vue"),
      },
      {
        path: "/help/nbiot",
        name: "help-nbiot-device",
        component: () => import("@/views/help/files/Mqtt.vue"),
      },
      {
        path: "/help/roles",
        name: "help-roles-device",
        component: () => import("@/views/help/files/Roles.vue"),
      },
      {
        path: "/help/adminpanel",
        name: "help-admin-device",
        component: () => import("@/views/help/files/Admin.vue"),
      },
      {
        path: "/help/daizy",
        name: "help-daizy-device",
        component: () => import("@/views/help/files/DaizyDoc.vue"),
      },
      {
        path: "/apps/billing/invoice",
        name: "invoices",
        component: () => import("@/views/billing/InvoiceList.vue"),
      },
      {
        path: "/apps/billing/create",
        name: "create",
        component: () => import("@/views/billing/CreateInvoice.vue"),
      },
      {
        path: "/apps/billing/update/:id",
        name: "update",
        component: () => import("@/views/billing/CreateInvoice.vue"),
      },
      {
        path: "/apps/csv/decoder",
        name: "csv-decoder",
        component: () =>
          import("@/views/provisioning/devices/Csv_Decoder/Decoder.vue"),
      },
      {
        path: "/administration/companies",
        name: "csv-listing",
        component: () =>
          import("@/views/apps/administration/companies/CustomerList.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/provisioning/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/provisioning/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import(
            "@/views/provisioning/authentication/basic-flow/PasswordReset.vue"
          ),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/provisioning/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/provisioning/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  await store.dispatch(Actions.VERIFY_AUTH);
  // Get token from localStorage
  const token = localStorage.getItem('id_token');
  // Check if the token exists or is invalid
  if (!token) {
    if (to.path !== '/sign-in') {
      return next({ path: '/sign-in' });
    }
  }
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  // Proceed with the navigation
  next();
});



export default router;
