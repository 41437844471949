import { ActionContext } from 'vuex';
import axios from 'axios';

interface State {
  // Define your state interface if you have specific properties
  // Example: customerList: Array<any>;
  customerName : string
}

const state: State = {
  // Initialize your state properties here
  // Example: customerList: []
  customerName : ""
};

const getters = {};

const actions = {
  async CustomerBillingListApi(
    { commit }: ActionContext<State, any>, // Action context with proper typing
    payload: any // Define the correct payload type if available
  ): Promise<any> {
    const base_url = process.env.VUE_APP_API_URL;
    const url = `${base_url}subscriptions/customer/listing`;

    return await axios.post(url, payload).then((response) => {
        return response.data
    }).catch((error)=>{
        return error.data
    })
  },
  async paymentDetailsApi(
    { commit }: ActionContext<State, any>, // Action context with proper typing
    payload: any // Define the correct payload type if available
  ): Promise<any> {
    const base_url = process.env.VUE_APP_API_URL;
    const customer_id = payload.id;
    const url = `${base_url}subscriptions/customer/${customer_id}`;

    return await axios.post(url, payload).then((response) => {
        return response.data
    }).catch((error)=>{
        return error.data
    })
  },
//   cancel Subscription Api Code start here
async cancelApi(
    { commit }: ActionContext<State, any>,
  ): Promise<any> {
    const base_url = process.env.VUE_APP_API_URL;
    const url = `${base_url}subscriptions/cancel`;

    return await axios.get(url).then((response) => {
        return response.data
    }).catch((error)=>{
        return error.response.data;
    })
  },

};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
